import { PrismicRichText } from "@prismicio/react";
import cn from "clsx";
import Image from "next/image";
import { Text } from "@components/ui";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";
import { useTranslation } from "next-i18next";
import style from "./BlocAssiette.module.scss";

const BlocAssiette = ({ slice }) => {
  const { t } = useTranslation();

  return slice.primary.activateOrDeactivate ? (
    <section>
      <div className={style.assiette}>
        <div className="lk-wrap">
          <EntryTitle className={style.entryTitle}>
            <PrismicRichText field={slice.primary.title} />
          </EntryTitle>
        </div>
        <div className="lk-wrap">
          <div className="lk-wrap-content">
            <div className="lk-flex space-between  lk-flex-smaltab-colomn">
              <div className={style.feature}>
                <Image
                  src={slice.primary.feature.url}
                  alt={slice.primary.feature.alt}
                  title={slice.primary.feature.alt}
                  width="608"
                  height="608"
                />
              </div>
              <div className={style.content}>
                <div>
                  <div className={style.values}>
                    {slice?.items?.map((item, i) => (
                      <div className={cn(style.item, style[`it${i}`])} key={i}>
                        <div className={style.top}>
                          <Image
                            src={item.icon.url}
                            alt={item.icon.alt}
                            title={item.icon.alt}
                            width={item.icon.dimensions.width}
                            height={item.icon.dimensions.height}
                          />
                          <Text variant="span">{item.name}</Text>
                        </div>
                        <div
                          className={cn(style.val, {
                            [style.reverse]: item.daily_first,
                          })}
                        >
                          <div className={style.industriel}>
                            <Text variant="span">{item.value_industriel}</Text>
                          </div>
                          <div className={style.daily}>
                            <Text variant="span">{item.value_ky}</Text>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={style.labels}>
                    <Text variant="span">{t("pages.industrielMeal")}</Text>
                    <Text variant="span">{t("pages.kitchendailyMeal")}</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export default BlocAssiette;
