import React from "react";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";
import { Link, Text } from "@components/ui";
import { URL_PAGE_FORMULES } from "@middleware/constants";
import { useTranslation } from "next-i18next";
import style from "./ChooseYourPlan.module.scss";

/**
 * @typedef {import("@prismicio/client").Content.ChooseYourPlanSlice} ChooseYourPlanSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ChooseYourPlanSlice>} ChooseYourPlanProps
 * @param { ChooseYourPlanProps }
 */
const ChooseYourPlan = ({ slice }) => {
  const { t } = useTranslation();

  return slice.primary.activateOrDeactivate ? (
    <section className={style.wrapper}>
      <div className="lk-wrap">
        <EntryTitle className={style.entryTitle}>
          <PrismicRichText field={slice.primary.title} />
        </EntryTitle>

        <div className={style.content}>
          <div className={style.items}>
            {slice.items.map((item, index) => (
              <div key={index} className={style.item}>
                <div className={style.img}>
                  <PrismicImage field={item.image} />
                </div>
                <EntryTitle className={style.title} variant="h4">
                  <PrismicRichText field={item.title} />
                </EntryTitle>
                <hr />
                <Text className={style.text}>
                  <PrismicRichText field={item.text} />
                </Text>
              </div>
            ))}
          </div>
          <div className={style.action}>
            <Link
              href={URL_PAGE_FORMULES}
              className="default rounded maxContent simple"
            >
              {t("buttons.chooseMyFormula")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export default ChooseYourPlan;
