import React from "react";
import { SliderSlick } from "@components/commun";
import { serializeTags } from "@middleware/helpers";
import { Text } from "@components/ui";
import Image from "next/image";
import style from "./TagsSlider.module.scss";
import { sliderSettings } from "./sliderSetting";

/**
 * @typedef {import("@prismicio/client").Content.TagsSliderSlice} TagsSliderSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TagsSliderSlice>} TagsSliderProps
 * @param { TagsSliderProps }
 */
const TagsSlider = ({ slice }) => {
  const tags = serializeTags(slice.items);

  return (
    <div className={style.tags}>
      <div className="lk-wrap large">
        <div className={style.slider}>
          <SliderSlick settings={sliderSettings}>
            {tags.map((tag, index) => (
              <div key={index} className={style.tag}>
                <div
                  className={style.img}
                  style={{ backgroundImage: `url(${tag.image})` }}
                >
                  <div className={style.content}>
                    <Image
                      className={style.icon}
                      src={tag.icon}
                      width={28}
                      height={28}
                    />
                    <Text className={style.name}>{tag.name}</Text>
                    <Text className={style.caption} html={tag.caption} />
                  </div>
                </div>
              </div>
            ))}
          </SliderSlick>
        </div>
      </div>
    </div>
  );
};

export default TagsSlider;
