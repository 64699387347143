import React, { useEffect, useState } from "react";
import { SliceZone } from "@prismicio/react";
import { Loader } from "@components/ui";
import { createClient } from "../../prismicio";
import { components } from "..";

/**
 * @typedef {import("@prismicio/client").Content.SliderTagPositionSlice} SliderTagPositionSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<SliderTagPositionSlice>} SliderTagPositionProps
 * @param { SliderTagPositionProps }
 */
const SliderTagPosition = ({ slice }) => {
  const contentRel = slice.primary.tags;
  const [tagsSlices, setTagsSlices] = useState();
  useEffect(() => {
    const getTags = async () => {
      const client = createClient();
      const tags = await client.getSingle("tags");
      setTagsSlices(tags.data.slices);
    };
    void getTags();
  }, [contentRel]);

  return tagsSlices !== undefined ? (
    <SliceZone slices={tagsSlices} components={components} />
  ) : (
    <div className="loaderContainer p-3 text-center">
      <Loader type="clip" color="#000" />
    </div>
  );
};

export default SliderTagPosition;
