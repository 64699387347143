import { PrismicRichText } from "@prismicio/react";
import cn from "clsx";
import Image from "next/image";
import { Text } from "@components/ui";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";
import style from "./MangezMieux.module.scss";

const MangezMieux = ({ slice }) => {
  return slice.primary.activateOrDeactivate ? (
    <section>
      <div className={style.mangez}>
        <div className="lk-wrap">
          <EntryTitle className={style.entryTitle}>
            <PrismicRichText field={slice.primary.title} />
          </EntryTitle>
        </div>
        <div className={cn(style.wrap, "lk-wrap")}>
          <div className="lk-wrap-content">
            <div className={cn("content", style.content)}>
              <div className={style.feature}>
                <Image
                  className={style.image}
                  src={slice.primary.feature.url ?? ""}
                  fill={true}
                  objectFit="contain"
                />
              </div>

              <div className={style.container}>
                <div className={cn(style.col, style.orange)}>
                  <Text variant="span" className={style.colTitle}>
                    {slice.primary.titre_orange}
                  </Text>
                  <section className={style.row}>
                    <Text variant="span">{slice.primary.label_orange_1}</Text>
                    <Text variant="p" html={slice.primary.text_orange_1} />
                  </section>
                  <section className={cn(style.row, style.aling)}>
                    <Text variant="span">{slice.primary.label_orange_2}</Text>
                    <Text variant="p" html={slice.primary.text_orange_2} />
                  </section>
                  <section className={cn(style.row, style.aling)}>
                    <Text variant="span">{slice.primary.label_orange_3}</Text>
                    <Text variant="p" html={slice.primary.text_orange_3} />
                  </section>
                  <section className={cn(style.row, style.last)}>
                    <Text variant="span">{slice.primary.label_orange_4}</Text>
                    <Text variant="p" html={slice.primary.text_orange_4} />
                  </section>
                  <div className={style.indice}>
                    <Text
                      variant="p"
                      html={slice.primary.recap_orange[0].text}
                    />
                  </div>
                </div>
                <div className={cn(style.col, style.vert)}>
                  <Text variant="span" className={style.colTitle}>
                    {slice.primary.titre_vert}
                  </Text>
                  <section className={style.row}>
                    <Text variant="span">{slice.primary.label_vert_1}</Text>
                    <Text variant="p" html={slice.primary.text_vert_1} />
                    <div className={style.list}>
                      <PrismicRichText field={slice.primary.liste_vert} />
                    </div>
                  </section>
                  <section className={cn(style.row, style.last)}>
                    <Text variant="span">{slice.primary.label_vert_2}</Text>
                    <Text variant="p" html={slice.primary.text_vert_2} />
                  </section>
                  <div className={style.indice}>
                    <Text variant="p" html={slice.primary.recap_vert[0].text} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export default MangezMieux;
