import cn from "clsx";
import { Button } from "@components/ui";
import { ICustomer, TFunctionFieldProps } from "@middleware/types";
import { useTranslation } from "next-i18next";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Api from "@middleware/api";
import {
  useAuth,
  useGlobalMessaging,
  useSchemaMessages,
  useUI,
} from "@middleware/hooks";
import Field from "@components/ui/Forms/Field/Field";
import schema from "./schema";
import { fields } from "./fields";
import style from "./ShareSponsorshipEmail.module.scss";

interface Props {
  customer: ICustomer;
}

export const ShareSponsorshipEmail = ({ customer }: Props) => {
  const { t } = useTranslation();
  const { user, setConnectedCustomer } = useAuth();
  const customSchema = schema(useSchemaMessages());
  const { showLoader, hideLoader } = useUI();
  const { setMessage } = useGlobalMessaging();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(customSchema),
  });

  const getFieldProps = ({ field }: TFunctionFieldProps) => {
    const fieldProps = {
      name: field.name,
      label: t(field.label ?? ""),
      placeholder: t(field.placeholder ?? ""),
      register: register,
      control: control,
      type: field.type,
      error: errors[field.name]?.message as string,
    };

    return fieldProps;
  };

  const onSubmit: SubmitHandler<FieldValues> = async (datas) => {
    if (!user) return;

    showLoader();
    datas.name = "";
    const res = await Api.customer.sponsorship(
      customer.id,
      datas.email as string
    );

    if (res.status !== 200) {
      setMessage({
        text: t("error.sorryMessage"),
        type: "ERROR",
      });
    } else {
      reset();
      setConnectedCustomer(user);
      setMessage({
        text: t("success.mailSent"),
        type: "SUCCESS",
      });
    }

    hideLoader();
  };

  return (
    <div className={style.form}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div>
          {fields.map((field, index) => {
            const fieldProps = getFieldProps({
              field,
              register,
              errors,
              control,
            });

            return (
              <div
                className={cn(style.filedRow, style[field.name])}
                key={index}
              >
                <Field variant={field.type} props={fieldProps} />
              </div>
            );
          })}

          <div className={style.action}>
            <Button
              type="submit"
              className={cn(style.btn, "rounded maxContent")}
              variant="default"
              puce={false}
              disabled={isSubmitting}
            >
              {t("buttons.send")}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
