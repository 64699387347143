import { Text } from "@components/ui";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import style from "./BlockParrainage.module.scss";

const BlockParrainage = ({ slice }) => (
  <section
    data-slice-type={slice.slice_type}
    data-slice-variation={slice.variation}
    className={style.wrapper}
  >
    <div className={style.box}>
      <div className={style.img}>
        <PrismicImage field={slice.primary.image} />
      </div>
      <div className={style.content}>
        <div>
          <Text className={style.title} variant="span">
            <PrismicRichText field={slice.primary.title} />
          </Text>
          <Text className={style.label}>
            <PrismicRichText field={slice.primary.text} />
          </Text>
        </div>
        <Text className={style.text}>
          <PrismicRichText field={slice.primary.sponsorshipNote} />
        </Text>
      </div>
    </div>
  </section>
);

export default BlockParrainage;
