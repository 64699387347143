import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { useWindow } from "@middleware/hooks";
import { Text } from "@components/ui";
import style from "./WeeklyThemeBanner.module.scss";

/**
 * @typedef {import("@prismicio/client").Content.WeeklyBannerSlice} WeeklyBannerSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<WeeklyBannerSlice>} WeeklyBannerProps
 * @param { WeeklyBannerProps }
 */
const WeeklyBanner = ({ slice }) => {
  const { isMobile } = useWindow();
  const image = isMobile
    ? slice.primary.imageMobile.url
    : slice.primary.imageDesktop.url;

  return (
    <div className={style.themeWrapper}>
      <div
        className=""
        style={{
          backgroundImage: `url(${image ?? ""})`,
          color: slice.primary.color?.toString(),
        }}
      >
        <div>
          <Text className={style.title}>
            <PrismicRichText field={slice.primary.title} />
          </Text>
          <Text className={style.themeName}>{slice.primary.themeName}</Text>
        </div>
      </div>
    </div>
  );
};

export default WeeklyBanner;
