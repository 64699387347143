import React from "react";
import { PrismicRichText } from "@prismicio/react";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";
import style from "./MealProcessSteps.module.scss";

/**
 * @typedef {import("@prismicio/client").Content.MealProcessStepsSlice} MealProcessStepsSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<MealProcessStepsSlice>} MealProcessStepsProps
 * @param { MealProcessStepsProps }
 */
const MealProcessSteps = ({ slice }) => (
  <section className={style.wrapper}>
    <div className="lk-wrap">
      <EntryTitle className={style.title}>
        <PrismicRichText field={slice.primary.title} />
      </EntryTitle>

      <div className={style.items}>
        {slice.items.map((item, index) => (
          <div className={style.item} key={index}>
            <div className={style.icon}>
              <video
                autoPlay
                muted
                loop
                playsInline
                aria-hidden="true"
                width="67"
                height="49"
              >
                <source src={item.video.url} type="video/mp4" />
              </video>
            </div>
            <div className={style.label}>{item.label}</div>
            <div className={style.caption}>
              <PrismicRichText field={item.caption} />
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default MealProcessSteps;
